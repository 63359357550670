import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import RichText from '~components/RichText';
import Container from '~components/Container';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '168px',
    paddingBottom: '168px'
  },
  logo: {
    maxWidth: '100%'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '50px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  heroImageContainer: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '-10%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    }
  },
  heroImage: {
    height: 'auto',
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0
    }
  },
  readMoreBtn: {
    fontSize: 16,
    fontWeight: 500
  },
  content: {
    maxWidth: '530px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    '& > p': {
      fontSize: '18px'
    },
    '& > p:empty': {
      height: '1px'
    },
    '& > h1': {
      fontSize: '36px'
    },
    '& > h2': {
      fontSize: '28px'
    },
    '& > h3': {
      fontSize: '22px'
    }
  }
}));

const AbLabsIntro = ({ logo, section_image, content }) => {
  const classes = useStyles();
  return (
    <Box bgcolor="#FFFDFC">
      <Container className={classes.container}>
        <Box display="flex" marginBottom="10px" maxWidth="490px">
          <img src={logo && logo.url} alt={logo && logo.alt} className={classes.logo} />
        </Box>
        <div className={classes.contentContainer}>
          <Box
            minWidth="50%"
            display="flex"
            flexDirection="column"
            gridColumnGap="5px"
            marginRight="30px"
          >
            <RichText html={content && content.html} externalClassName={classes.content} />
            <Link to="/about/aleph-beta-labs" className={classes.readMoreBtn}>
              Read More
            </Link>
          </Box>
          <div className={classes.heroImageContainer}>
            <img
              src={section_image && section_image.url}
              alt={section_image && section_image.alt}
              className={classes.heroImage}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
};

AbLabsIntro.propTypes = {
  logo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  section_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  content: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired
};

export default AbLabsIntro;
